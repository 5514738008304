import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Navigation from "../components/navigation"
import 'prismjs/themes/prism-okaidia.css';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';



const useStyles = makeStyles((theme) => ({
  topbar: {
    flexGrow: 1,
    
    backgroundColor:'Black',
    
  },

  footerbar: {
    flexGrow: 1,
    textAlign: 'center',
    
  },
 
  title: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    
    color: theme.palette.text.secondary,
  },
}));

export default ({ children }) => {
  const classes = useStyles();
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <>

      




<div className={classes.topbar}>
      <AppBar position="static" className={classes.topbar}>
        <Toolbar>
          
          <Typography variant="h4" className={classes.title}>
          <Link to="/">{data.site.siteMetadata.title}</Link>
          </Typography>
          <Button color="inherit"><Navigation /></Button>
        </Toolbar>
      </AppBar>
    </div>

    <div className="site-wrapper">
      
      {children}
      </div>

      <div >
      <AppBar position="static" className={classes.footerbar}>
        <Toolbar>
          <Button color="red"><footer>
        <p>&copy; 2020 Ali Raza Khan </p>
      </footer></Button>
        </Toolbar>
      </AppBar>
    </div>

      
    
    </>
  )
}